body header {
    width: 100vw;
    position: fixed;
    top: 0px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    background-color: var(--dark);
    padding: 15px 0;
    z-index: 3;
  
    .header_container {
        display: flex;
        flex-direction: row;
        max-width: 88%;
        width: 100%;
        align-items: center;
        justify-content: space-between;
  
        @media only screen and (min-width: 1200px) {
            max-width: 1200px;
        }
    }

    h1 {
        font-size: 4vw;
        text-transform: uppercase;
        background: linear-gradient(
          0deg,
          rgba(255, 0, 255, 0) 0%,
          rgba(255, 0, 255, 0) 16%,
          #7c91cc 16%,
          #7c91cc 41%,
          rgba(255, 0, 255, 0) 41%
        );
        padding: 0 0.5em 0 0.5em;
        box-decoration-break: clone;
        -webkit-box-decoration-break: clone;
    }
      
      nav a {
        text-decoration: none;
        color: var(--primary);
        padding: 11px 11px;
      }
      
      nav .active {
        background: linear-gradient(
          0deg,
          rgba(255, 0, 255, 0) 0%,
          rgba(255, 0, 255, 0) 20%,
          #7c91cc 16%,
          #7c91cc 50%,
          rgba(255, 0, 255, 0) 41%
        );
        box-decoration-break: clone;
        -webkit-box-decoration-break: clone;
      }
      
      nav a:hover {
        background: linear-gradient(
          0deg,
          rgba(255, 0, 255, 0) 0%,
          rgba(255, 0, 255, 0) 20%,
          #7c91cc 16%,
          #7c91cc 50%,
          rgba(255, 0, 255, 0) 41%
        );
        color: var(--primary);
        box-decoration-break: clone;
        -webkit-box-decoration-break: clone;
        text-decoration: none;
      }
      
      nav ul {
        list-style: none;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        font-weight: bold;
        text-transform: uppercase;
        font-size: 1.1rem;
        list-style-type: none;
        margin: 0;
        padding: 0;
      }
      
      .mobile {
        display: block;
        position: absolute;
        right: 0;
        text-align: right;

        @media only screen and (min-width: 800px) {
          display: none;
        }
      }    
      
      nav.mobile ul {
        flex-direction: column;
        align-items: flex-end;
        padding-right: 15px;
        margin-top: 21px;
        padding-top: 16px;
        width: 100vw;
        height: 100vh;
        background-color: rgba(12, 14, 14, 0.9);
      }
      
      nav.mobile li {
        margin-bottom: 12px;
      }
      
      .icon-close {
        display: none;
      }
      
      .icon-close svg {
        fill: #ffffff;
      }
      
      .icon-hamburger {
        stroke: #fff;
      }
      
      details > summary:first-of-type {
        display: block;
        padding-right: 33px;
      }
      
      details>summary {
        list-style: none;
      }
      
      details > summary {
        list-style: none;
      }
      
      details > summary::marker, /* Latest Chrome, Edge, Firefox */ 
      details > summary::-webkit-details-marker /* Safari */ {
        display: none;
      }
      
      details[open] > summary .icon-hamburger {
        display: none;
      }
      
      details[open] > summary .icon-close {
        display: block;
      }
      
      nav li {
        list-style: none;
        display: inline-block;
      }
}
