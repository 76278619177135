body footer {
    padding: 25px 0;
    background-color: var(--dark);
    width: 100%;
    margin-top: auto;
    display: flex;
    justify-content: center;
    width: 100%;
  }
  
  .footer_container {
    display: flex;
    flex-direction: initial;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 88%;

    @media only screen and (min-width: 1200px) {
        max-width: 1200px;
    }
  }
  
  .footer_email a {
    color: var(--primary);
  }
  
  .footer_icons {
    display: flex;
    gap: 15px;
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .footer_icons a:hover svg {
    fill: var(--secondary);
  }
  
  footer svg {
    fill: var(--primary);
  }
  
  address ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    flex-wrap: wrap;
    height: 100%;
    font-size: 1.3rem;
    font-style: normal;
    justify-content: space-around;
    display: flex;
  }
  
  address a {
    text-decoration: none;
    color: white;
    padding: 0 30px 0 0;
    font-size: 1rem;
  }