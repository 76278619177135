/* PROJECTS */

section.projects {
    display: flex;
    align-content: center;
    justify-content: center;
    background-color: black;
    color: white;
    margin: 0;
    padding: 50px 0;

    div.projects_container {
        max-width: 88%;
        align-self: center;

        @media only screen and (min-width: 1200px) {
            max-width: 1200px;
        }

        h2 {
            text-align: left;
            padding-bottom: 10px;
            margin-bottom: 35px;
            border-bottom: 1px solid;
            border-right: none;

            &::before { 
                display: block; 
                content: " "; 
                margin-top: -140px; 
                height: 140px; 
                visibility: hidden; 
                pointer-events: none;
            }
        }

        .carousel {

            .carousel-status {
                width: 100%;
                text-align: center;
            }

            .control-prev {
                padding-left: 0;
            }

            .control-next {
                padding-right: 0;
            }

            .control-dots {
                z-index: 2;

                .dot {
                    width: 15px;
                    height: 15px;
                    margin: 0px 15px;
                }
            }
            .slider-wrapper {
                overflow: hidden;
                margin: auto;
                width: 80%;
                transition: height .15s ease-in;

                @media only screen and (min-width: 700px) {
                    width: 90%;
                }
            }

            .slide {
                padding-bottom: 40px;

                .project_image-container {
                    width: 100%;
                    height: auto;
                    border-radius: 5px;
                    padding: 0;
    
                    @media only screen and (min-width: 700px) {
                        width: 50%;
                        height: auto;
                    }
                }

                img {
                    height: auto;
                }

                // .project_image-container {
                //     width: 100%;
                //     height: auto;
                //     border-radius: 5px;
                //     padding: 0;
    
                //     @media only screen and (min-width: 700px) {
                //         width: 52%;
                //         height: auto;
                //     }
                // }
            }
        }

        .card-body {
            text-align: left;
            border: none;
            padding: 0;

            @media only screen and (min-width: 700px) {
                padding-left: 30px;
                width: 50%;
            }

            .card-title {
                padding-top: 30px;
                text-transform: uppercase;

                @media only screen and (min-width: 700px) {
                    padding-top: 0;
                }
            }

            .card-text {
                font-weight: 200;
            }

            p i {
                font-weight: 200;
            }
        }
    }
}
  
  .btn-primary {
    background-color: var(--secondary);
    border-color: var(--secondary);
    margin-right: 20px;
    margin-bottom: 10px;
    color: var(--primary);
  }
  
  .btn-primary:hover {
    background-color: var(--light);
  }

section.project {
    flex-direction: column;
    margin-left: 1px;

    @media only screen and (min-width: 700px) {
        flex-direction: row;
    }
}
