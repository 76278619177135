:root {
  --body: #0f2531;
  --primary: white;
  --dark: rgb(12 14 14);
  --secondary: #5E74B0;
  --light: #b7c4e9;
}

main {
  margin-top: 80px;
}

body {
  margin: 0;
  padding: 0;
  background-color: var(--body);
  color: var(--primary);
  line-height: 1.5;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  height: 100vh;
}

#root {
  height: 100vh;
}

.body-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

h1,
h2,
h3 {
  font-size: 1rem;
}
h4,
h5,
h6 {
  margin: 0;
}

p {
  margin: 0;
}

a {
  color: var(--secondary);
}

a:hover {
  color: var(--light);
  text-decoration: underline;
}

a.anchor {
  display: block;
  position: relative;
  top: -250px;
  visibility: hidden;
}

.btn-primary {
  color: #fff;
  background-color: var(--secondary);
  border: none;
}

.btn-primary:hover {
  color: #fff;
  background-color: var(--body);
  border: none;
}

section {
  margin: 25px 0;
  padding: 10px 0;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
}

section h2 {
  flex: 0 0 20%;
  text-align: right;
  padding-right: 17px;
  border-right: 1px solid;
  font-size: 4vw;
  line-height: 1;
}

section p {
  margin-bottom: 25px;
}

/* HOME */

.about_info_container {
  flex: 0 0 72%;
}

.profile_img {
  float: left;
  width: 35%;
  max-width: 300px;
  min-width: 120px;
  height: auto;
  margin: 0 25px 0px 0;
  display: inline;
}

a.logo {
  scale: unset;
}

@media only screen and (max-width: 450px) {
  body {
    font-size: 0.8rem;
  }

  header h1 {
    font-size: 12vw;
  }

  p {
    font-size: 0.8rem;
  }

  a.logo {
    display: flex;
    scale: 0.7;
    transform-origin: left;
    -webkit-transform-origin-x: left;
  }

  section {
    flex-direction: column;
  }
  section h2 {
    text-align: left;
    padding-bottom: 10px;
    margin-bottom: 15px;
    border-bottom: 1px solid;
    border-right: 0;
    font-size: 1rem;
    min-width: 200px;
  }
  .card-img {
    width: 100%;
  }
  .card-title {
    font-size: 6vw;
  }
  .card-body {
    margin: 0;
    padding: 10px;
    border: 0;
  }
  .container {
    margin: 0;
    padding: 0 20px 0 20px;
  }
  .resume h2 {
    font-size: 1.2rem;
  }
  .resume h3 {
    font-size: 1rem;
  }
  .form {
    width: auto;
  }
  .form .btn-primary {
    width: auto;
  }
  address ul {
    flex-direction: column;
  }
}

@media only screen and (max-width: 800px) {
  .resume {
    width: 100%;
  }
  .desktop {
    display: none;
  }
}
